import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/layout";
import InfoPage from "../components/Page/info-page";

const Masks = ({ data }) => (
    <Layout page={"masks"}>
        <InfoPage {...data.contentfulInfoPage} />
    </Layout>
);

export default Masks;

export const query = graphql`
  query {
    contentfulInfoPage(name: {eq: "masks"}) {
      id
      name
      heading
      body {
        childMarkdownRemark {
            html
        }
      }
      showSafetyLink
      updatedAt
    }
  }
`;